import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Content from "../components/content"
import CallToAction from "../components/cta"
import { graphql } from "gatsby"

const IndexPage = ({data}) => {
  const releaseNotes = data.allContentfulRelease.edges[0].node.releaseNotes.releaseNotes
  const downloadUrl =  data.allContentfulRelease.edges[0].node.downloadUrl.downloadUrl

  return <Layout> 
    <SEO title="Welcome" />
    <Hero url={downloadUrl} />
    <Content />
    <CallToAction url={downloadUrl} releaseNotes={releaseNotes} />
  </Layout>
}

export const query = graphql`
query {
  allContentfulRelease(sort:{fields:releaseDate, order:DESC}) {
    edges {
      node {
        releaseDate
        versionNumber
        releaseNotes {
          releaseNotes
        }
        downloadUrl {
          downloadUrl
        }
      }
    }
  }
}`

export default IndexPage
