import React from "react"

import feature from "../images/feature.png"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const Content = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Read"
      description="Data Composer can read data from many sources including Microsoft SQL Server, local and network files, and even websites!"
    />

    <SectionHeader
      title="Transform"
      description="Data Composer lets you transform your data using modern Javascript! You can write your code in the browser as part of your configuration."
    />

    <SectionHeader
      title="Write"
      description="Write your data to an unlimited number of destinations including databases, files, web requests, and more!"
    />
  </div>
)

export default Content
