import React from "react"
import PropTypes from "prop-types"
import Button from "../components/button"
import headerImage from "../images/header.png"
import logo from '../images/logo.svg'
import composerScreenshot from "../images/data-composer-screenshot.png"

const Hero = ({ siteTitle, url }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "2rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100%",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center", display:'flex', alignItems:'center' }}>
      <img src={logo} width='40' style={{marginBottom:0, marginRight:'5px'}} alt="Data Composer Logo"/>
      Data Composer
    </h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      Read. Transform. Write.
    </p>
    <Button url={url}>Download</Button>
    <div style={{ marginTop: 30, maxWidth:'900px', position: "relative" }}>
        <img
          src={composerScreenshot}
          alt="Data Composer screenshot"
        />
    </div>
  </div>
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
