import React from "react"
import Button from "../components/button"
import SectionHeader from "./section-header"

const CallToAction = ({url, releaseNotes}) => {
  return <div style={{ paddingTop: "4rem", paddingBottom:"4rem", textAlign: "center", margin:'auto', maxWidth:'700px' }}>
    <SectionHeader
      title="Version History"
      description="Latest Updates for Data Composer"
    />
    <div style={{textAlign: 'left', paddingBottom: '2rem'}} dangerouslySetInnerHTML={{
    __html: releaseNotes,
    }} />
    <Button url={url}>Download</Button>
  </div>
}

export default CallToAction
