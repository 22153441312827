import React from "react"
import { COLORS, BORDER_RADIUS, GRADIENT } from "../styles/constants"
import "../styles/button.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDownload, fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

const Button = ({ children, url }) => (
  <button
    onClick={() => {
      window.location.href = url
    }}
    style={{
      padding: ".5rem 2.5rem",
      color: COLORS.lightWhite,
      fontWeight: 700,
      background: GRADIENT,
      borderRadius: BORDER_RADIUS,
      borderWidth: 0,
      cursor: "pointer",
    }}
  >
    <FontAwesomeIcon icon={faDownload} fixedWidth style={{marginRight:'4px'}} />
    {children}
  </button>
)

export default Button
